<script lang="ts" setup>
const linecapList = ["inherit", "butt", "round", "square"] as const;

const props = withDefaults(
	defineProps<{
		value: number;
		size?: number;
		color?: string;
		colorValue?: string;
		strokeWidth?: number;
		linecap?: (typeof linecapList)[number];
		gradientId?: string;
		colorStartGrad?: string;
		colorStopGrad?: string;
	}>(),
	{
		value: 42,
		size: 68,
		color: "var(--a-progress-circle-default-color)",
		colorValue: "var(--a-progress-circle-default-color-value)",
		strokeWidth: 4,
		colorStartGrad: "var(--a-progress-circle-default-start-grad)",
		colorStopGrad: "var(--a-progress-circle-default-stop-grad)",
		gradientId: ""
	}
);
const strokeDasharray = props.size * 2;
const radius = Math.floor(props.size / 3);
const halfSize = props.size / 2;
const pValue = computed(() => strokeDasharray - (strokeDasharray * props.value) / 100);
</script>

<template>
	<svg class="circle-progress" :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
		<defs>
			<linearGradient :id="gradientId" gradientTransform="rotate(300)">
				<stop offset="0%" :stop-color="colorStartGrad" />
				<stop offset="30%" :stop-color="colorStopGrad" />
			</linearGradient>
		</defs>
		<circle
			:r="radius"
			:cx="halfSize"
			:cy="halfSize"
			fill="transparent"
			:stroke="color"
			:stroke-width="`${strokeWidth}px`"
		/>
		<circle
			:r="radius"
			:cx="halfSize"
			:cy="halfSize"
			fill="transparent"
			:stroke-width="`${strokeWidth}px`"
			:stroke-linecap="linecap"
			:stroke-dasharray="strokeDasharray"
			:stroke-dashoffset="pValue"
			:stroke="`${gradientId ? `url(#${gradientId})` : colorValue}`"
			class="circle-progress-value"
		/>
	</svg>
</template>

<style lang="scss">
:root {
	--a-progress-circle-default-color: var(--cankaya);
	--a-progress-circle-default-color-value: var(--coimbatore);
	--a-progress-circle-default-start-grad: var(--chihuahua);
	--a-progress-circle-default-stop-grad: var(--coimbatore);
}

@if mixin-exists(AProgressCircle) {
	@include AProgressCircle;
}
</style>

<style lang="scss" scoped>
.circle-progress {
	transform: rotate(-90deg);
	&-value {
		transition: all 2s linear;
	}
}
</style>
